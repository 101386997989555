@font-face {
	font-family: 'open-sans';
	src: url('/fonts/open-sans-400.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'open-sans';
	src: url('/fonts/open-sans-600.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'agenda';
	src: url('/fonts/agenda-600.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'agenda';
	src: url('/fonts/agenda-700.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'zooja-pro';
	src: url('/fonts/zooja-pro-400.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'zooja-pro';
	src: url('/fonts/zooja-pro-300.woff2') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

.regular-10 {
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}

.regular-12 {
	font-weight: 400;
	font-size: 12px !important;
	line-height: 20px;
}

.regular-14 {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}

.regular-16 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.regular-20 {
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
}

.regular-24 {
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

.regular-30 {
	font-weight: 400;
	font-size: 30px;
	line-height: 38px;
}

.regular-38 {
	font-weight: 400;
	font-size: 38px;
	line-height: 46px;
}

.regular-46 {
	font-weight: 400;
	font-size: 46px;
	line-height: 54px;
}

.regular-56 {
	font-size: 56px;
	font-weight: 400;
	line-height: 64px;
}

.semibold-12 {
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	font-weight: 600;
}

.semibold-14 {
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	font-weight: 600;
}

.semibold-16 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	font-weight: 600;
}

.semibold-20 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	font-weight: 600;
}

.semibold-24 {
	font-family: agenda, sans-serif;
	font-weight: 600;
	font-size: calc(24px * 1.1);
	line-height: 32px;
	font-weight: 600;
}

.semibold-30 {
	font-family: agenda, sans-serif;
	font-weight: 700;
	font-size: calc(30px * 1.1);
	line-height: 38px;
	font-weight: 700;
}

.semibold-38 {
	font-family: agenda, sans-serif;
	font-weight: 700;
	font-size: calc(38px * 1.1);
	line-height: 46px;
	font-weight: 700;
}

.semibold-46 {
	font-family: agenda, sans-serif;
	font-weight: 700;
	font-size: calc(46px * 1.1);
	line-height: 54px;
	font-weight: 700;
}

.semibold-56 {
	font-family: agenda, sans-serif;
	font-weight: 700;
	font-size: calc(56px * 1.1);
	line-height: 64px;
	font-weight: 700;
}

.link {
	text-decoration: underline;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.5s;
}

.link:hover {
	opacity: 0.8;
}

.align-center {
	text-align: center;
}
